<template>
  <div>
    <div class="grads">
      <div class="displaySwitch">
        <h3 :class="isDisplayProPrice ? '' : 'activeText'">
          {{ $t('grading.freePlan') }}
        </h3>
        <el-switch
          :value="isDisplayProPrice"
          @change="value => $emit('changeDisplayPrice', value)"
        />
        <h3 :class="`pro ${isDisplayProPrice ? 'activeText' : ''}`">
          {{ $t('grading.proPlan') }}
          <span class="saveText">Saves 20%</span>
        </h3>
      </div>
      <div class="grading">
        <div class="header">
          <div class="title">
            <h3>{{ $t('grading.scores') }}</h3>
          </div>
          <div class="instruction">
            <p>{{ $t('grading.basedOnTheOfficialRubricFromETS') }}</p>
          </div>
        </div>
        <div class="feature" :key="score.id" v-for="score in points.scores">
          <div class="title">
            <h3>{{ $t(`grading.${score.id}`) }}</h3>
            <span>
              {{ testTypeInstruction[score.id] }}
            </span>
          </div>
          <div class="pointContainer">
            <span class="point">
              {{ score.point }}
            </span>
            <span>T-Coins</span>
          </div>
        </div>
      </div>
      <div class="grading">
        <div class="header">
          <div class="title">
            <h3>{{ $t('grading.scoresSimpleComments1') }}<br />{{ $t('grading.scoresSimpleComments2') }}</h3>
          </div>
          <div class="instruction">
            <p>{{ $t('grading.yourScoreAndComments') }}<router-link :to="{ name: 'Gradingsamples' }">{{ $t('grading.seeExample') }}</router-link>{{ $t('grading.close') }}</p>
          </div>
        </div>
        <div class="feature" :key="simple.id" v-for="simple in points.simples">
          <div class="title">
            <h3>{{ $t(`grading.${simple.id}`) }}</h3>
            <span>
              {{ testTypeInstruction[simple.id] }}
            </span>
          </div>
          <div class="pointContainer">
            <span class="point">
              {{ simple.point }}
            </span>
            <span>T-Coins</span>
          </div>
        </div>
      </div>
      <div class="grading">
        <div class="header">
          <div class="recommend">Recommended</div>
          <div class="title">
            <h3>{{ $t('grading.scoresVipComments1') }}<br />{{ $t('grading.scoresVipComments2') }}</h3>
          </div>
          <div class="instruction">
            <p>{{ $t('grading.yourScoreAndDetailedComments') }}<router-link :to="{ name: 'Gradingsamples' }">{{ $t('grading.seeExample') }}</router-link>{{ $t('grading.close') }}</p>
          </div>
        </div>
        <div class="feature" :key="vip.id" v-for="vip in points.vips">
          <div class="title">
            <h3>{{ $t(`grading.${vip.id}`) }}</h3>
            <span>
              {{ testTypeInstruction[vip.id] }}
            </span>
          </div>
          <div v-if="vip.point" class="pointContainer">
            <span class="point">
              {{ vip.point }}
            </span>
            <span>T-Coins</span>
          </div>
          <div v-else class="pointContainer">
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "getPoint",
    "categories",
    "toeflTypes",
    "singleSection",
    "isDisplayProPrice"
  ],
  computed: {
    testTypeInstruction() {
      return {
        fullMock: this.$t("grading.speakingAndWriting", { speakingCount: 4, writingCount: 2, s: "s" }),
        quickMock: this.$t("grading.speakingAndWriting", { speakingCount: 1, writingCount: 1 }),
        speakingSection: this.$t("grading.speaking", { speakingCount: 4, s: "s" }),
        writingSection: this.$t("grading.writing", { writingCount: 2, s: "s" }),
        speakingResponse: this.$t("grading.speaking", { speakingCount: 1 }),
        writingResponse: this.$t("grading.writing", { writingCount: 1 })
      };
    },
    points() {
      return {
        scores: [
          {
            id: "fullMock",
            point: this.getPoint(this.categories.score, this.toeflTypes.fullMock)
          },
          {
            id: "quickMock",
            point: this.getPoint(this.categories.score, this.toeflTypes.quickMock)
          },
          {
            id: "speakingSection",
            point: this.getPoint(this.categories.score, this.toeflTypes.section, this.singleSection.speaking)
          },
          {
            id: "writingSection",
            point: this.getPoint(this.categories.score, this.toeflTypes.section, this.singleSection.writing)
          },
          {
            id: "speakingResponse",
            point: this.getPoint(this.categories.score, this.toeflTypes.response, this.singleSection.speaking)
          },
          {
            id: "writingResponse",
            point: this.getPoint(this.categories.score, this.toeflTypes.response, this.singleSection.writing)
          }
        ],
        simples: [
          {
            id: "fullMock",
            point: this.getPoint(this.categories.simple, this.toeflTypes.fullMock)
          },
          {
            id: "quickMock",
            point: this.getPoint(this.categories.simple, this.toeflTypes.quickMock)
          },
          {
            id: "speakingSection",
            point: this.getPoint(this.categories.simple, this.toeflTypes.section, this.singleSection.speaking)
          },
          {
            id: "writingSection",
            point: this.getPoint(this.categories.simple, this.toeflTypes.section, this.singleSection.writing)
          },
          {
            id: "speakingResponse",
            point: this.getPoint(this.categories.simple, this.toeflTypes.response, this.singleSection.speaking)
          },
          {
            id: "writingResponse",
            point: this.getPoint(this.categories.simple, this.toeflTypes.response, this.singleSection.writing)
          }
        ],
        vips: [
          {
            id: "fullMock",
            point: this.getPoint(this.categories.vip, this.toeflTypes.fullMock)
          },
          {
            id: "quickMock",
            point: this.getPoint(this.categories.vip, this.toeflTypes.quickMock)
          },
          {
            id: "speakingSection",
            point: ""
          },
          {
            id: "writingSection",
            point: this.getPoint(this.categories.vip, this.toeflTypes.section, this.singleSection.writing)
          },
          {
            id: "speakingResponse",
            point: ""
          },
          {
            id: "writingResponse",
            point: this.getPoint(this.categories.vip, this.toeflTypes.response, this.singleSection.writing)
          }
        ]
      };
    }
  }
};
</script>

<style scoped lang="scss">
.grads {
  display: none;
}

@media screen and (max-width: 1220px) {
  .container {
    .grads {
      display: flex;
      width: calc(100vw - 60px);
      margin: 0px auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .grading {
      background: #fff;
      box-shadow: 1px 1px 16px 4px #cccccc;
      margin-bottom: 24px;
      padding-top: 24px;
      padding-bottom: 16px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;

      .header {
        position: relative;
        color: #999999;
        .recommend {
          position: absolute;
          background: #f0ad4e;
          color: #fff;
          padding: 2px 16px;
          top: -36px;
          left: calc(50% - 70px);
        }
  
        .title {
          h3 {
            color: var(--themeColor);
          }
        }
      }

      .feature {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 12px;

        .title {
          h3 {
            font-size: 20px;
            margin-bottom: 0px;
          }

          span {
            color: #999999;
          }
        }

        .pointContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;

          .point {
            color: #f0ad4e;
            margin-right: 20px;
            font-size: 36px;
          }
        }
      }
    }

    .displaySwitch {
      margin-top: 20px;
      margin-bottom: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: #999999;
    
      .activeText {
        color: var(--themeColor);
      }
    
      .pro {
        position: relative;
    
        .saveText {
          color: orange;
          position: absolute;
          top: 32px;
          left: -14px;
          width: 140px;
        }
      }
    }
  }
}
</style>
