<template>
  <el-menu :default-active="activePage" class="el-menu-demo" mode="horizontal">
    <el-menu-item index="ToeflPlans" class="menu-item"
      ><b
        ><router-link :to="{ name: 'ToeflPlans' }" class="linkInTab">{{
          $t("header.plans")
        }}</router-link></b
      ></el-menu-item
    >
    <el-menu-item index="ToeflPlansGrading" class="menu-item">
      <b>
        <router-link :to="{ name: 'ToeflPlansGrading' }" class="linkInTab">
          {{ $t("header.gradingAndCommenting") }}
        </router-link>
      </b>
    </el-menu-item>
    <el-menu-item index="ToeflPlansTCoins" class="menu-item">
      <b>
        <router-link :to="{ name: 'ToeflPlansTCoins' }" class="linkInTab">
          {{ $t("toefl.T-Coin") }}
        </router-link>
      </b>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activePage: "ToeflPlans"
    };
  },
  created() {
    this.activePage = this.$route.name;
  }
};
</script>

<style scoped>
.item >>> .el-badge__content.is-fixed {
  transform: translateX(100%);
}
.item >>> .el-badge__content {
  line-height: 16px;
}

.el-menu.el-menu--horizontal {
  padding: 0px 32px;
  margin-bottom: 20px;
  background: transparent;
}

::v-deep .el-menu-item:hover {
  background: transparent !important;
}

.linkInTab {
  color: inherit !important;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}

.menu-item {
  position: relative;
}
</style>
