<template>
  <div class="container">
    <PageTitle class="pageTitle">
      {{ pageTitle }}
    </PageTitle>
    <PlanMenu />
    <div class="subTitle">
      <h2>{{ $t("header.gradingAndCommenting") }}</h2>
      <p>{{ $t("grading.subTitle") }}</p>
    </div>
    <div class="grading">
      <div class="features">
        <div>
          <h3>{{ $t('grading.fullMock') }}</h3>
          <p>
            {{
              $t("grading.speakingAndWriting", {
                speakingCount: 4,
                writingCount: 2,
                s: "s"
              })
            }}
          </p>
        </div>
        <div>
          <h3>{{ $t('grading.quickMock') }}</h3>
          <p>
            {{
              $t("grading.speakingAndWriting", {
                speakingCount: 1,
                writingCount: 1
              })
            }}
          </p>
        </div>
        <div>
          <h3>{{ $t('grading.speakingSection') }}</h3>
          <p>
            {{
              $t("grading.speaking", {
                speakingCount: 4,
                s: "s"
              })
            }}
          </p>
        </div>
        <div>
          <h3>{{ $t('grading.writingSection') }}</h3>
          <p>
            {{
              $t("grading.writing", {
                writingCount: 2,
                s: "s"
              })
            }}
          </p>
        </div>
        <div>
          <h3>{{ $t('grading.speakingResponse') }}</h3>
          <p>
            {{
              $t("grading.speaking", {
                speakingCount: 1
              })
            }}
          </p>
        </div>
        <div>
          <h3>{{ $t('grading.writingResponse') }}</h3>
          <p>
            {{
              $t("grading.writing", {
                writingCount: 1
              })
            }}
          </p>
        </div>
      </div>
      <div>
        <div class="displaySwitch">
          <h3 :class="isDisplayProPrice ? '' : 'activeText'">
            {{ $t('grading.freePlan') }}
          </h3>
          <el-switch :value="isDisplayProPrice" @change="changeDisplayPrice" />
          <h3 :class="`pro ${isDisplayProPrice ? 'activeText' : ''}`">
            {{ $t('grading.proPlan') }}
            <span class="saveText">Saves 20%</span>
          </h3>
        </div>
        <div class="availableGrads">
          <div class="availableGrad">
            <div class="header">
              <div class="title">
                <h3>{{ $t('grading.scores') }}</h3>
              </div>
              <div class="instruction">
                <p>{{ $t('grading.basedOnTheOfficialRubricFromETS') }}</p>
              </div>
            </div>
            <div class="content">
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.score, toeflTypes.fullMock) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.score, toeflTypes.quickMock) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.score, toeflTypes.section, singleSection.speaking) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.score, toeflTypes.section, singleSection.writing) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.score, toeflTypes.response, singleSection.speaking) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.score, toeflTypes.response, singleSection.writing) }}</span>
                <span>T-Coins</span>
              </div>
            </div>
          </div>
          <div class="availableGrad">
            <div class="header">
              <div class="title">
                <h3>{{ $t('grading.scoresSimpleComments1') }}<br />{{ $t('grading.scoresSimpleComments2') }}</h3>
              </div>
              <div class="instruction">
                <p>
                  {{ $t('grading.yourScoreAndComments') }}<router-link :to="{ name: 'Gradingsamples', query: { type: 'simple' } }">{{ $t('grading.seeExample') }}</router-link>{{ $t('grading.close') }}
                </p>
              </div>
            </div>
            <div class="content">
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.simple, toeflTypes.fullMock) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.simple, toeflTypes.quickMock) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.simple, toeflTypes.section, singleSection.speaking) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.simple, toeflTypes.section, singleSection.writing) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.simple, toeflTypes.response, singleSection.speaking) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.simple, toeflTypes.response, singleSection.writing) }}</span>
                <span>T-Coins</span>
              </div>
            </div>
          </div>
          <div class="availableGrad">
            <div class="header">
              <div class="recommend">Recommended</div>
              <div class="title">
                <h3>{{ $t('grading.scoresVipComments1') }}<br />{{ $t('grading.scoresVipComments2') }}</h3>
              </div>
              <div class="instruction">
                <p>
                  {{ $t('grading.yourScoreAndDetailedComments') }}<router-link :to="{ name: 'Gradingsamples', query: { type: 'vip' } }">{{ $t('grading.seeExample') }}</router-link>{{ $t('grading.close') }}
                </p>
              </div>
            </div>
            <div class="content">
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.vip, toeflTypes.fullMock) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.vip, toeflTypes.quickMock) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                -
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.vip, toeflTypes.section, singleSection.writing) }}</span>
                <span>T-Coins</span>
              </div>
              <div class="pointContainer">
                -
              </div>
              <div class="pointContainer">
                <span class="point">{{ getPoint(categories.vip, toeflTypes.response, singleSection.writing) }}</span>
                <span>T-Coins</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MobileGrading
      :getPoint="getPoint"
      :categories="categories"
      :toeflTypes="toeflTypes"
      :singleSection="singleSection"
      :isDisplayProPrice="isDisplayProPrice"
      @changeDisplayPrice="changeDisplayPrice"
    />
  </div>
</template>

<script>
import planApi from "@/apis/plans";
import PlanMenu from "@/views/toefl/plans/PlanMenu.vue";
import MobileGrading from "@/views/toefl/plans/MobileGrading.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle}: ${this.$t("header.gradingAndCommenting")} - ` + this.CompanyName
    };
  },
  components: {
    PlanMenu,
    MobileGrading,
    PageTitle
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.PlansAndPricing");
    },
    categories() {
      return {
        score: "1",
        simple: "4",
        vip: "5"
      };
    },
    toeflTypes() {
      return {
        fullMock: "App\\Entities\\Toefl",
        quickMock: "App\\Entities\\ToeflQuickMock",
        section: "App\\Entities\\ToeflSingleSection",
        response: "App\\Entities\\ToeflChapter"
      };
    },
    singleSection() {
      return {
        speaking: 3,
        writing: 4
      };
    }
  },
  data() {
    return {
      isDisplayProPrice: true,
      pointPackages: []
    };
  },
  async created() {
    const { point_packages } = await planApi.pointPackages();
    this.pointPackages = point_packages;

    if (this.$route.query.type) {
      this.isDisplayProPrice = this.$route.query.type === "pro";
    }
  },
  methods: {
    getPoint(scoreType, testType, toeflSkillId) {
      let targets = this.pointPackages.filter(({ category, toefl_type }) => (
        category === scoreType && testType === toefl_type
      ));

      if (this.pointPackages.length === 0) return "";

      let target = null;
      if (targets.length === 1) {
        target = targets[0];
      } else {
        target = targets.find(({ toefl_skill_id }) => (
          toefl_skill_id === toeflSkillId
        ));
      }
      if (this.isDisplayProPrice) {
        return target.point_pro;
      } else {
        return target.point;
      }
    },
    changeDisplayPrice(isDisplayProPrice) {
      this.$router.push({
        name: "",
        query: {
          type: isDisplayProPrice ? "pro" : "free"
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  max-width: 100vw;
  margin: 0px;
  padding: 40px 0px;
  background: #f4f5f8;

  .pageTitle {
    padding-left: 32px !important;
  }

  .subTitle {
    width: calc(100vw - 60px);
    margin: 0px auto;

    p {
      font-weight: 500;
    }
  }

  .grading {
    width: calc(100vw - 60px);
    margin: 0px auto;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: calc((100vw - 60px) / 4) auto;
  }
}

.features {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding: 8px 4px 0px 8px;
  margin: 341px -10px 0px 20px;
  display: grid;
  grid-gap: 4px;
  grid-template-rows: repeat(6, 120px);

  h3 {
    font-weight: bold;
    margin-bottom: 4px;
  }

  p {
    color: #999999;
  }
}

::v-deep .el-switch {
  margin: 0px 16px;
}

.displaySwitch {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #999999;

  .activeText {
    color: var(--themeColor);
  }

  .pro {
    position: relative;

    .saveText {
      color: orange;
      position: absolute;
      top: 32px;
      left: -14px;
      width: 140px;
    }
  }
}

.availableGrads {
  display: flex;
  box-shadow: 1px 1px 16px 4px #cccccc;
  background: #ffffff;

  .availableGrad {
    width: calc(100% / 3);

    .header {
      padding: 20px;
      padding-bottom: 0px;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 240px;
      border-bottom: 1px solid #ddd;
      position: relative;

      .recommend {
        position: absolute;
        background: #f0ad4e;
        color: #fff;
        padding: 2px 16px;
        top: -12px;
        font-weight: 800;
      }

      .title {
        display: flex;
        align-items: center;
        height: 120px;
        h3 {
          text-align: center;
          color: var(--themeColor);
        }
      }
      
      .instruction {
        height: 120px;
        p {
          text-align: center;
          color: #999999;
        }
      }
    }

    .content {
      display: grid;
      grid-gap: 4px;
      grid-template-rows: repeat(6, 120px);

      .pointContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        .point {
          color: #f0ad4e;
          margin-right: 20px;
          font-size: 48px;
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .container {
    .grading {
      display: none;
    }
  }
}
</style>
