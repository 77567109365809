import ApiService from "@/common/api.service";

export default {
  upgradePlan(category) {
    return ApiService.post("member-package", { category });
  },
  pointPackages() {
    return ApiService.get("point-packages");
  },
  subscribePro(data) {
    return ApiService.post("subscriptions/payment-link", data);
  },
  getSubscriptions() {
    return ApiService.query("/subscriptions");
  },
  unsubscribe(id) {
    return ApiService.post(`/subscriptions/${id}/unsubscribe`);
  },
  reactivating(id) {
    return ApiService.post(`subscriptions/${id}/reactivating`);
  }
};
